import React from 'react';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import kebabCase from 'lodash/kebabCase';
import Root from '../components/root';
import SideMenu from '../components/side-menu';

const TopicsRoute = (props) => {
  const { title } = props.data.site.siteMetadata;
  const topics = props.data.allMarkdownRemark.group;

  return (
    <Root>
      <div>
        <Helmet title={'All Topics'} />
        <SideMenu location={props.location} siteMetadata={props.data.site.siteMetadata} />
        <div className="content">
          <div className="content__inner">
            <div className="page">
              <h1 className="page__title">Topics</h1>
              <div className="page__body">
                <div className="topics">
                  <ul className="topics__list">
                    {topics.map(topic => (
                      <li key={topic.fieldValue} className="topics__list-item">
                        <Link
                          to={`/topics/${kebabCase(topic.fieldValue)}/`}
                          className="topics__list-item-link"
                        >
                          {topic.fieldValue} ({topic.totalCount})
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default TopicsRoute;

export const pageQuery = graphql`
  query TopicsQuery {
    site {
      siteMetadata {
        siteTitle
        subtitle
        copyright
        menu {
          label
          path
          link
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" }, listed: { eq: true }, draft: { ne: true } } }
    ) {
      group(field: frontmatter___topics) {
        fieldValue
        totalCount
      }
    }
  }
`;
